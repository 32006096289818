import type { PieCustomLayerProps, PieSvgProps } from "@nivo/pie";
import { ResponsivePie } from "@nivo/pie";

type Data = {
  id: string | number;
  value: number;
  innerLabel?: string;
  label?: string;
};

interface Props
  extends Pick<PieSvgProps<Data>, "data" | "valueFormat" | "colors"> {
  innerLabel?: string;
  customColors?: boolean;
}

export default function PieChart({
  data,
  innerLabel,
  customColors,
  ...rest
}: Props) {
  const injectedData = innerLabel
    ? [{ ...data[0], innerLabel }, ...data.slice(1)]
    : data;
  return (
    <ResponsivePie
      margin={{ top: 30, right: 80, bottom: 30, left: 80 }}
      innerRadius={0.5}
      data={injectedData}
      colors={customColors ? { datum: "data.color" } : undefined}
      layers={[
        "arcs",
        "arcLabels",
        "arcLinkLabels",
        "legends",
        ...(innerLabel ? [InnerLabel] : [])
      ]}
      {...rest}
    />
  );
}

const InnerLabel = ({
  centerX,
  centerY,
  dataWithArc
}: PieCustomLayerProps<Data>) => {
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "12px",
        fontWeight: 600
      }}
    >
      {dataWithArc[0].data.innerLabel}
    </text>
  );
};
