import type { LoaderFunctionArgs } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import Alert from "~/components/alert";
import NavLink from "~/components/nav-link";
import NavWrapper from "~/components/nav-wrapper";
import { ScoreboardUserContext } from "~/contexts";
import { getContentTopics } from "~/models/content.server";
import {
  getBillableHoursCount,
  getDynamicMetrics,
  getPortfolioContribution,
  getProjectMetrics,
  getProspectMeetingsMetric
} from "~/models/dynamic-metric.server";
import { getAllKanbanCards } from "~/models/kanban.server";
import { getScoreboardMilestones } from "~/models/metric.server";
import {
  getCampaigns,
  getProjectCounts,
  getPUM,
  getReceivables,
  getRecentlyBilled
} from "~/models/project.server";
import { getSalesMetrics } from "~/models/sales-metric.server";
import { getTaskCounts } from "~/models/task.server";
import { getUser } from "~/models/user.server";
import DashboardAR from "~/routes/_index/dashboard-ar";
import DashboardIT from "~/routes/_index/dashboard-it";
import DashboardMS from "~/routes/_index/dashboard-ms";
import DashboardPresident from "~/routes/_index/dashboard-president";
import DashboardStandard from "~/routes/_index/dashboard-standard";
import { authorize, getUserId } from "~/sessions.server";
import { useCurrentUser } from "~/utils/auth";
import { formatDate } from "~/utils/dates";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await authorize(request);
  const params = new URL(request.url).searchParams;
  const quarter = formatDate(new Date(), { format: "YYYY-'Q'Q" });

  const id = params.get("user") || (await getUserId(request));
  const {
    user,
    user: { dashboard }
  } = await getUser(id, request);
  if (dashboard === "M&S") {
    const [
      { taskCounts },
      { projectCounts },
      milestoneData,
      kanbanData,
      projectMetricsData,
      campaignData,
      salesMetricsData,
      contentData
    ] = await Promise.all([
      getTaskCounts({ userId: id }, request),
      getProjectCounts({ userId: id, mode: "Campaign" }, request),
      getScoreboardMilestones(
        {
          quarter,
          userId: user.id
        },
        request
      ),
      getAllKanbanCards({ userId: id, includeUser: true }, request),
      getProjectMetrics(
        {
          auditUserId: id,
          userId: id,
          quarter
        },
        request
      ),
      getCampaigns({ filters: ["Active"], userId: id }, request),
      getSalesMetrics(
        {
          userId: id,
          includeUser: true,
          date:
            params.get("date") ||
            formatDate(new Date(), { format: "YYYY-MM-DD" }),
          period: params.get("interval") || "Monthly"
        },
        request
      ),
      getContentTopics({ ownerId: id }, request)
    ]);
    return {
      dashboard: "M&S" as const,
      user,
      taskCounts,
      kanbanData,
      milestoneData,
      projectCounts,
      projectMetricsData,
      campaignData,
      salesMetricsData,
      contentData,
      quarter
    };
  } else if (dashboard === "A/R") {
    const [
      { taskCounts },
      { projectCounts },
      milestoneData,
      kanbanData,
      projectMetricsData,
      { dynamicMetrics },
      receivablesData
    ] = await Promise.all([
      getTaskCounts({ userId: id }, request),
      getProjectCounts({ userId: id }, request),
      getScoreboardMilestones(
        {
          quarter,
          userId: user.id
        },
        request
      ),
      getAllKanbanCards({ userId: id, includeUser: true }, request),
      getProjectMetrics(
        {
          userId: id,
          filter: "Collections",
          quarter
        },
        request
      ),
      getDynamicMetrics(
        {
          types: ["A/R Collections"],
          quarter
        },
        request
      ),
      getReceivables(
        {
          includeAging: false,
          filter: { status: "Not Dead", anyTagIds: ["148", "175", "161"] }
        },
        request
      )
    ]);
    return {
      dashboard: "A/R" as const,
      user,
      taskCounts,
      milestoneData,
      kanbanData,
      projectCounts,
      projectMetricsData,
      dynamicMetrics,
      receivablesData,
      quarter
    };
  } else if (dashboard === "IT") {
    const [
      { taskCounts },
      { projectCounts },
      milestoneData,
      kanbanData,
      projectMetricsData
    ] = await Promise.all([
      getTaskCounts({ userId: id }, request),
      getProjectCounts({ userId: id }, request),
      getScoreboardMilestones(
        {
          quarter,
          userId: user.id
        },
        request
      ),
      getAllKanbanCards({ userId: id, includeUser: true }, request),
      getProjectMetrics(
        {
          auditUserId: id,
          userId: id,
          quarter
        },
        request
      )
    ]);
    return {
      dashboard: "IT" as const,
      user,
      taskCounts,
      milestoneData,
      kanbanData,
      projectCounts,
      projectMetricsData,
      quarter
    };
  } else if (dashboard === "President") {
    const [
      { taskCounts },
      { projectCounts },
      milestoneData,
      kanbanData,
      projectMetricsData,
      prospectMeetingData
    ] = await Promise.all([
      getTaskCounts({ userId: id }, request),
      getProjectCounts({ userId: id }, request),
      getScoreboardMilestones(
        {
          quarter,
          userId: user.id
        },
        request
      ),
      getAllKanbanCards({ userId: id, includeUser: true }, request),
      getProjectMetrics(
        {
          auditUserId: id,
          userId: id,
          quarter
        },
        request
      ),
      getProspectMeetingsMetric(
        { quarter, userId: user.id, type: "Prospect Meetings: President" },
        request
      )
    ]);
    return {
      dashboard: "President" as const,
      user,
      taskCounts,
      milestoneData,
      kanbanData,
      projectCounts,
      projectMetricsData,
      quarter,
      prospectMeetingData
    };
  } else {
    const [
      { taskCounts },
      { projectCounts },
      kanbanData,
      { dynamicMetrics },
      milestoneData,
      { employee },
      pumData,
      projectMetricsData,
      portfolioData,
      recentlyBilledData
    ] = await Promise.all([
      getTaskCounts({ userId: id }, request),
      getProjectCounts({ userId: id }, request),
      getAllKanbanCards({ userId: id, includeUser: true }, request),
      getDynamicMetrics(
        {
          userId: id,
          quarter,
          types: [
            "Billable Hours",
            "Billable Hours: Goal",
            "Billable Hours: Target"
          ]
        },
        request
      ),
      getScoreboardMilestones(
        {
          quarter,
          userId: user.id
        },
        request
      ),
      getBillableHoursCount(
        {
          userId: id,
          includeEmployee: true,
          date: formatDate(new Date(), { format: "YYYY-MM-DD" })
        },
        request
      ),
      getPUM({ includeUser: true, userId: id }, request),
      getProjectMetrics(
        {
          auditUserId: id,
          userId: id,
          quarter
        },
        request
      ),
      getPortfolioContribution(
        { userId: id, month: formatDate(new Date(), { format: "YYYY-MM" }) },
        request
      ),
      getRecentlyBilled({ userId: id }, request)
    ]);
    return {
      dashboard: "Standard" as const,
      user,
      taskCounts,
      pumData,
      dynamicMetrics,
      kanbanData,
      milestoneData,
      employee,
      projectCounts,
      projectMetricsData,
      portfolioData,
      recentlyBilledData,
      quarter
    };
  }
};

export const meta = () => [{ title: "Home/Dashboard" }];

export default function Individual() {
  const currentUser = useCurrentUser();
  const {
    user,
    user: { dashboard }
  } = useLoaderData<typeof loader>();

  return (
    <NavWrapper
      menuItems={
        <>
          <NavLink to="/kpis" label="My KPIs" />
          <NavLink
            to="/me"
            label={
              currentUser.employee?.employeeType === "Employee"
                ? "My Employee Page"
                : "My Contractor Page"
            }
          />
        </>
      }
    >
      <ScoreboardUserContext.Provider value={user}>
        {dashboard === "Standard" ? (
          <DashboardStandard />
        ) : dashboard === "M&S" ? (
          <DashboardMS />
        ) : dashboard === "A/R" ? (
          <DashboardAR />
        ) : dashboard === "IT" ? (
          <DashboardIT />
        ) : dashboard === "President" ? (
          <DashboardPresident />
        ) : (
          <Alert mode="danger">Unknown dashboard</Alert>
        )}
      </ScoreboardUserContext.Provider>
    </NavWrapper>
  );
}
