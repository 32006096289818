import type { ProjectCountsQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import clsx from "clsx";
import { useContext } from "react";
import ButtonLink from "~/components/button-link";
import Grid from "~/components/grid";
import Link from "~/components/link";
import Panel from "~/components/panel";
import { ScoreboardUserContext } from "~/contexts";

type Props = {
  mode?: "Projects" | "Campaigns";
};

export default function ScoreboardProject({ mode = "Projects" }: Props) {
  const { projectCounts } = useLoaderData<{
    projectCounts: ProjectCountsQuery["projectCounts"];
  }>();
  const user = useContext(ScoreboardUserContext)!;

  const active = projectCounts.find((c) => c.name === "Active")!.count!;
  const hold = projectCounts.find((c) => c.name === "Hold")!.count!;
  const untouched = projectCounts.find((c) => c.name === "Untouched")!.count!;
  const overBudget = projectCounts.find(
    (c) => c.name === "Over Budget"
  )!.count!;
  const billedTo = projectCounts.find((c) => c.name === "Billed To")!.count!;
  const noTasks = projectCounts.find((c) => c.name === "No Actions")!.count!;

  return (
    <Panel className="mb-0">
      <Panel.Header
        title={mode}
        className="text-center"
        floatButton
        button={
          <ButtonLink
            to={`/projects?user=${user.id}`}
            className="show-when-hover-parent"
            small
          >
            Project List
          </ButtonLink>
        }
      />
      <Panel.Body className="p-0">
        <Grid columns={3} itemClass="text-center">
          <Link to={`/projects?filter=active&user=${user.id}`}>
            <h4
              className={clsx(
                "m-0",
                active > 0 ? "text-success" : "text-muted"
              )}
            >
              Active
              <div className="mt-4">{active}</div>
            </h4>
          </Link>
          <Link to={`/projects?filter=hold&user=${user.id}`}>
            <h4 className="text-muted m-0">
              Hold
              <div className="mt-4">{hold}</div>
            </h4>
          </Link>
          <Link
            to={`/projects?advancedSearch=[{"criteria":"Status","values":["Active","HOLD"]},{"value":"${user.id}","criteria":"Staff+(Any+Role)"},{"value":"Untouched","criteria":"Custom"}${mode === "Campaigns" ? ',{"value":"Campaign","criteria":"Mode"}' : ""}]`}
          >
            <h4
              className={clsx(
                "m-0",
                untouched > 0 ? "text-danger" : "text-muted"
              )}
            >
              Untouched
              <div className="mt-4">{untouched}</div>
            </h4>
          </Link>
          <Link
            to={`/projects?user=ALL&status=All&advancedSearch=[{"criteria":"Status","values":["Active","Future","Permanent","Automated","HOLD"]},{"value":"${user.id}","criteria":"Staff+(Any+Role)"},{"value":"Over Budget","criteria":"Custom"}${mode === "Campaigns" ? ',{"value":"Campaign","criteria":"Mode"}' : ""}]`}
          >
            <h4
              className={clsx(
                "m-0",
                overBudget > 0 ? "text-danger" : "text-muted"
              )}
            >
              Over Budget
              <div className="mt-4">{overBudget}</div>
            </h4>
          </Link>
          <Link
            to={`/projects?user=ALL&status=All&advancedSearch=[{"criteria":"Status","values":["Active","Future","Permanent","Automated","HOLD"]},{"value":"${user.id}","criteria":"Staff+(Any+Role)"},{"value":"No+Actions+or+Cards","criteria":"Custom"}${mode === "Campaigns" ? ',{"value":"Campaign","criteria":"Mode"}' : ""}]`}
          >
            <h4
              className={clsx(
                "m-0",
                noTasks > 0 ? "text-danger" : "text-muted"
              )}
            >
              No Actions
              <div className="mt-4">{noTasks}</div>
            </h4>
          </Link>
          <Link
            to={`/projects?user=ALL&status=All&advancedSearch=[{"criteria":"Status","values":["Active","Future","Permanent","Automated","HOLD"]},{"value":"${user.id}","criteria":"Staff+(Any+Role)"},{"value":"Recently Billed","criteria":"Custom"}${mode === "Campaigns" ? ',{"value":"Campaign","criteria":"Mode"}' : ""}]`}
          >
            <h4
              className={clsx("m-0", billedTo > 0 ? "text-info" : "text-muted")}
            >
              Billed To
              <div className="mt-4">{billedTo}</div>
            </h4>
          </Link>
        </Grid>
      </Panel.Body>
    </Panel>
  );
}
