import type { ReceivablesQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import Alert from "~/components/alert";
import ButtonLink from "~/components/button-link";
import Link from "~/components/link";
import NumberedTable from "~/components/numbered-table";
import Panel from "~/components/panel";
import { formatMoney } from "~/utils/formatting";

interface Props {
  tagId: string;
  top10?: boolean;
  title: string;
}
export default function CollectionsProjects({ tagId, top10, title }: Props) {
  const {
    receivablesData: { projectSearch }
  } = useLoaderData<{ receivablesData: ReceivablesQuery }>();

  const all = _.orderBy(
    projectSearch.projects.filter((p) => p.tags.some((t) => t.id === tagId)),
    (p) => parseFloat(p.totalDue),
    "desc"
  );
  const top = top10 ? all.slice(0, 10) : all;

  return (
    <Panel>
      <Panel.Header
        className="text-center"
        title={title}
        floatButton
        button={
          <ButtonLink
            to={`/ar/receivables?tags=${tagId}`}
            small
            className="show-when-hover-parent"
          >
            View All
          </ButtonLink>
        }
      />
      {top.length ? (
        <NumberedTable>
          <thead>
            <tr>
              <th />
              <th className="text-center">Number</th>
              <th>Name</th>
              <th>Client</th>
              <th className="text-right">Due</th>
            </tr>
          </thead>
          <tbody>
            {top.map((p) => (
              <tr key={p.id}>
                <td />
                <td className="text-center">
                  <Link to={`/projects/${p.number}/invoices`}>{p.number}</Link>
                </td>
                <td>
                  <Link to={`/projects/${p.number}/invoices`}>{p.name}</Link>
                </td>
                <td>
                  {p.memberships
                    .filter((m) => m.primaryContact)
                    .map((m) => (
                      <div key={m.id}>
                        <Link to={`/contacts/${m.contact.id}`}>
                          {m.contact.informalDisplayAs}
                        </Link>
                      </div>
                    ))}
                </td>
                <td className="text-right">{formatMoney(p.totalDue)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            {top10 && (
              <tr className="success">
                <th colSpan={4} className="text-right">
                  Total Due (Top 10):
                </th>
                <th className="text-right">
                  {formatMoney(
                    top.reduce(
                      (acc, item) => acc + parseFloat(item.totalDue),
                      0
                    )
                  )}
                </th>
              </tr>
            )}
            <tr className="success">
              <th colSpan={4} className="text-right">
                Total Due{top10 ? " (All Projects)" : ""}:
              </th>
              <th className="text-right">
                {formatMoney(
                  all.reduce((acc, item) => acc + parseFloat(item.totalDue), 0)
                )}
              </th>
            </tr>
          </tfoot>
        </NumberedTable>
      ) : (
        <Alert mode="warning">No Projects</Alert>
      )}
    </Panel>
  );
}
