import type { TaskCountsQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import clsx from "clsx";
import { useContext } from "react";
import ButtonLink from "~/components/button-link";
import Grid from "~/components/grid";
import Panel from "~/components/panel";
import { ScoreboardUserContext } from "~/contexts";

export default function TaskCounts() {
  const { taskCounts } = useLoaderData<{
    taskCounts: TaskCountsQuery["taskCounts"];
  }>();
  const user = useContext(ScoreboardUserContext)!;

  const overdue = taskCounts.find((c) => c.name === "Overdue")!.count!;
  const today = taskCounts.find((c) => c.name === "Today")!.count!;
  const tomorrow = taskCounts.find((c) => c.name === "Tomorrow")!.count!;

  return (
    <Panel>
      <Panel.Header
        title={"Actions"}
        className="text-center"
        floatButton
        button={
          <ButtonLink
            to={`/actions?user=${user.id}`}
            className="show-when-hover-parent"
            small
          >
            View List
          </ButtonLink>
        }
      />
      <Panel.Body className="p-0">
        <Grid columns={3} itemClass="text-center">
          <h4
            className={clsx("m-0", overdue > 0 ? "text-danger" : "text-muted")}
          >
            Overdue
            <div className="mt-4">{overdue}</div>
          </h4>
          <h4
            className={clsx("m-0", today > 0 ? "text-warning" : "text-muted")}
          >
            Today
            <div className="mt-4">{today}</div>
          </h4>
          <h4
            className={clsx("m-0", tomorrow > 0 ? "text-info" : "text-muted")}
          >
            Tomorrow
            <div className="mt-4">{tomorrow}</div>
          </h4>
        </Grid>
      </Panel.Body>
    </Panel>
  );
}
